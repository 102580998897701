import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  ¡Hola!
    </Text>
    <p>{`Soy Abraham Kuri - amante de Ruby y de la magia. Tengo un `}<a parentName="p" {...{
        "href": "http://apionrails.icalialabs.com/book"
      }}>{`libro`}</a>{` sobre construcción de APIs con Rails. CTO y co-fundador de `}<a parentName="p" {...{
        "href": "https://icalialabs.com"
      }}>{`IcaliaLabs`}</a>{`. Me apasiona enseñar y escribir código.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      